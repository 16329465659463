exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-progetti-il-cammino-dei-cappuccini-index-mdx": () => import("./../../../src/pages/progetti/il-cammino-dei-cappuccini/index.mdx" /* webpackChunkName: "component---src-pages-progetti-il-cammino-dei-cappuccini-index-mdx" */),
  "component---src-pages-progetti-index-js": () => import("./../../../src/pages/progetti/index.js" /* webpackChunkName: "component---src-pages-progetti-index-js" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-energia-europa-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/energia-europa/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-energia-europa-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-englishland-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/englishland/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-englishland-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-il-cammino-dei-cappuccini-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/il-cammino-dei-cappuccini/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-il-cammino-dei-cappuccini-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-steamit-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/steamit/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-steamit-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-analisi-seo-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/analisi-seo/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-analisi-seo-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-assistenza-suitecrm-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/assistenza-suitecrm/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-assistenza-suitecrm-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-campagne-annunci-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/campagne-annunci/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-campagne-annunci-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-siti-web-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/realizzazione-siti-web/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-siti-web-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-web-app-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/realizzazione-web-app/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-web-app-index-mdx" */),
  "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-sviluppo-app-mobile-index-mdx": () => import("./../../../src/pages/progetti/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/sviluppo-app-mobile/index.mdx" /* webpackChunkName: "component---src-pages-progetti-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-sviluppo-app-mobile-index-mdx" */),
  "component---src-pages-servizi-index-js": () => import("./../../../src/pages/servizi/index.js" /* webpackChunkName: "component---src-pages-servizi-index-js" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-energia-europa-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/energia-europa/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-energia-europa-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-englishland-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/englishland/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-englishland-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-il-cammino-dei-cappuccini-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/il-cammino-dei-cappuccini/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-il-cammino-dei-cappuccini-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-steamit-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/progetti/steamit/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-progetti-steamit-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-analisi-seo-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/analisi-seo/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-analisi-seo-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-assistenza-suitecrm-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/assistenza-suitecrm/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-assistenza-suitecrm-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-campagne-annunci-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/campagne-annunci/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-campagne-annunci-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-siti-web-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/realizzazione-siti-web/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-siti-web-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-web-app-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/realizzazione-web-app/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-realizzazione-web-app-index-mdx" */),
  "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-sviluppo-app-mobile-index-mdx": () => import("./../../../src/pages/servizi/{mdx.frontmatter__slug}.js?__contentFilePath=/home/kilenovo/REACT/gatsby/pixiel4/src/pages/servizi/sviluppo-app-mobile/index.mdx" /* webpackChunkName: "component---src-pages-servizi-mdx-frontmatter-slug-js-content-file-path-home-kilenovo-react-gatsby-pixiel-4-src-pages-servizi-sviluppo-app-mobile-index-mdx" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

